/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'ngx-toastr/toastr';
@import "tailwindcss";


$raleway: 'Raleway', sans-serif;

$mat-typography: mat.m2-define-typography-config(
  $font-family: $raleway
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($mat-typography);`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated */
//@include mat.all-legacy-component-typographies($mat-typography);
//@include mat.all-component-typographies($mat-typography);
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated */
//@include mat.legacy-core();
@include mat.elevation-classes();
@include mat.app-background();

:root {
  --primary-white: #fff;
  --primary-blue: #02a4ff;
  --primary-violet: #7d40ff;
  --background-white: #ffffff;

  --UI-disable: #cccccc;
  --UI-error: #FF3743;
  --UI-success: #35BB42;

  --typography-primary: #252525;
  --typography-gray: #a3a3a3;
  --typography-tertiary: #bbbbbb;

  // Block of font sizes variables with step of 2-4px between values in clamp default for 1920 width viewport
  --font-xxs: 1rem; // 10px
  --font-xs: 1.2rem; // 12px
  --font-sm: 1.4rem; // 14px
  --font-base: 1.6rem; // 16px
  --font-lg: 1.8rem; // 18px
  --font-xl: 2rem; // 20px
  --font-2xl: 2.4rem; // 24px
  --font-3xl: 3.2rem; // 32px


  // Block of paddings variables with step of 5-10px between values in clamp
  --pad-5: clamp(0.5rem, 0.026vw, 0.5rem); // 5px
  --pad-10: clamp(0.5rem, 0.052vw, 1.5rem); // 10px
  --pad-15: clamp(1rem, 0.078vw, 2rem); // 15px
  --pad-20: clamp(1.5rem, 0.104vw, 2.5rem); // 20px
  --pad-25: clamp(2rem, 0.131vw, 3rem); // 25px
  --pad-30: clamp(2.5rem, 0.156vw, 3.5rem); // 30px
  --pad-35: clamp(3rem, 0.182vw, 4rem); // 35px
  --pad-40: clamp(3rem, 0.208vw, 5rem); // 40px
  --pad-50: clamp(4rem, 0.260vw, 6rem); // 50px
  --pad-80: clamp(7rem, 0.416vw, 9rem); // 80px
  --pad-100: clamp(9rem, 0.520vw, 11rem); // 100px

  font-family: 'Raleway', sans-serif;
  font-weight: 500;

  @media (max-width: 1200px) {
    --font-xxs: clamp(0.8rem, 0.521vw, 1.2rem); // 10px
    --font-xs: clamp(1rem, 0.625vw, 1.4rem); // 12px
    --font-sm: clamp(1.2rem, 0.729vw, 1.6rem); // 14px
    --font-base: clamp(1.4rem, 0.833vw, 1.8rem); // 16px
    --font-lg: clamp(1.4rem, 0.938vw, 2.2rem); // 18px
    --font-xl: clamp(1.6rem, 1.042vw, 2.4rem); // 20px
    --font-2xl: clamp(2rem, 1.25vw, 2.8rem); // 24px
    --font-3xl: clamp(2.8rem, 1.666vw, 3.6rem); // 32px
  }

  @media (max-width: 768px) {
    --font-xs: 1.2rem;
    --font-sm: 1.4rem;
    --font-base: 1.6rem;
    --font-lg: 1.8rem;
    --font-xl: 2rem;
    --font-2xl: 2.4rem;
    --font-3xl: 3.2rem;

    --pad-5: 0.5rem;
    --pad-10: 1rem;
    --pad-15: 1.5rem;
    --pad-20: 2rem;
    --pad-25: 2.5rem;
    --pad-30: 3rem;
    --pad-35: 3.5rem;
    --pad-40: 4rem;
    --pad-50: 5rem;
    --pad-80: 8rem;
    --pad-100: 10rem;
  }

  @include mat.slider-overrides((
    ripple-color: rgb(33, 150, 243, 0.2),
    hover-state-layer-color: rgb(33, 150, 243, 0.1),
    focus-handle-color: rgb(33, 150, 243, 1),
    handle-color: rgb(33, 150, 243, 1),
    active-track-color: rgb(33, 150, 243, 1),
  ));
}

@font-face {
  font-family: 'Rising Sun';
  src: url('/assets/fonts/RisingSun-Regular.otf');
}

@font-face {
  font-family: 'Mograch';
  src: url("/assets/fonts/Mograch.otf");
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #F5F5F5;
  overflow-x: hidden;
  font-size: 62.5%; // Set root font size to 10px(default 16px) and apply for user font-size if he did set it

  ::-webkit-scrollbar {
    width: 12px !important;
  }

  ::-webkit-scrollbar-track {
    background: #CCC;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary-blue);
    width: 10%;
    border-radius: 3px;
  }
}

body {
  display: flex;
  flex-direction: column;
  font-family: $raleway;
}

app-root {
  flex: 1;
  display: flex;
}

button {
  user-select: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

.custom-dialog-container .mat-dialog-container {
  box-shadow: 0 8px 16px 0 rgba(53, 53, 53, 0.04) !important;
}
